import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import {  Divider, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { LOGOUT } from '../../../stores/actions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function AccountPopover() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const MENU_OPTIONS = [
    {
      label: 'Accueil',
      icon: '/',
      to:'/'
    },
    {
      label: 'Profil',
      icon: user.avatar,
      to: "/users/" + user.id
    },
  ];
  const handleLogout = () => {
		dispatch( { type: LOGOUT } );
    toast.warning("Vous êtes bien déconnecté");
		navigate(`/`);
	};
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={user.avatar} alt="photoURL" />
      </IconButton>

      { open && <Popover
        open={open}
        anchorEl={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <Link key={option.label} style={{color: "black"}} to={option.to}>
              <MenuItem onClick={handleClose}>
                {option.label}
              </MenuItem>
            </Link>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={() => {handleLogout(); handleClose()}} sx={{ m: 1 }}>
          Déconnexion
        </MenuItem>
      </Popover>}
    </>
  );
}
