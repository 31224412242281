import { lazy } from "react";

const Login = lazy(() => import("./Visitors/Login"));
const Register = lazy(() => import("./Visitors/Register"));
const NoMatch = lazy(() => import("./NoMatch"));
const Dashboard = lazy(() => import("./Dashboard"));
const FieldBooks = lazy(() => import("./FieldBooks/Index"));
const CreateFieldBook = lazy(() => import("./FieldBooks/create"));
const FieldBook = lazy(() => import("./FieldBooks/Show"));
const UpdateFieldBook = lazy(() => import("./FieldBooks/update"));
const Contacts = lazy(() => import("./Contacts"));
const Contact = lazy(() => import("./Contacts/show"));
const UpdateContact = lazy(() => import("./Contacts/update"));
const CreateEnterprise = lazy(() => import("./Enterprises/create"));
const UpdateEnterprise = lazy(() => import("./Enterprises/update"));
const Enterprise = lazy(() => import("./Enterprises/show"));
const Dealers = lazy(() => import("./Dealers"));
const CreateDealer = lazy(() => import("./Dealers/create"));
const Dealer = lazy(() => import("./Dealers/show"));
const UpdateDealer = lazy(() => import("./Dealers/update"));
const CreateSiteConnection = lazy(() => import("./FieldBooks/Show/SiteConnections/Create"));
const CreatePrestation = lazy(() => import("./FieldBooks/Show/Prestations/Create"));
const TabCreateSite = lazy(() => import("./FieldBooks/Show/SiteConnections/TabCreateSite"));
const TabCreatePrestation = lazy(() => import("./FieldBooks/Show/Prestations/TabCreatePrestation"));
const Users = lazy(() => import("./Users"));
const User = lazy(() => import("./Users/show"));
const UpdateUser = lazy(() => import("./Users/update"));
const UpdateUserPassword = lazy(() => import("./Users/updatePassword"));
const AdministratorPanel = lazy(() => import("./AdministratorPanel"));
const ForgotPassword = lazy(() => import("./Visitors/ForgotPassword"));

export {
    Enterprise,
    UpdateEnterprise,
    CreateEnterprise,
    TabCreateSite,
    TabCreatePrestation,
    CreateSiteConnection,
    CreatePrestation,
    AdministratorPanel,
    UpdateUser,
    User,
    Users,
    UpdateDealer,
    CreateDealer,
    Dealer,
    Dealers,
    UpdateContact,
    Contact,
    Contacts,
    CreateFieldBook,
    FieldBook,
    FieldBooks,
    UpdateFieldBook,
    Login,
    Register,
    ForgotPassword,
    UpdateUserPassword,
    NoMatch,
    Dashboard
}