import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// @mui
import {
  Box,
  List,
  Badge,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListItemButton,
} from "@mui/material";
// utils
// components
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { useFetch } from "../../../hooks/useFetch";
import moment from "moment";

export default function NotificationsPopover() {
  moment.locale("fr");
  const { responseData: notificationsInit, get } = useFetch(true);
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(null);

  useEffect(() => {
    if (!notificationsInit) {
      get("notifications?priority=1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notificationsInit) {
      setNotifications(notificationsInit);
    }
  }, [notificationsInit]);

  const handleOpen = (event) => {
    get("notifications?priority=1");
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={notifications?.length} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          mt: 0,
          ml: 0,
          width: "300 !important",
          maxHeight: 420,
        }}
      >
        <Box sx={{ py: 2, px: 2.5 }}>
          <Typography variant="subtitle1">Notifications</Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Vous avez {notificationsInit?.length} messages en attente
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />
        {notificationsInit && (
          <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
            <List disablePadding>
              {notifications?.map((notification, index) => (
                <NotificationItem
                  key={String(index)}
                  notification={notification}
                />
              ))}
            </List>
          </Scrollbar>
        )}
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({ notification }) {
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!notification.notification_recipient_copies[0].read && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <Link
        to={"fieldbooks/" + notification.on_click_url}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <ListItemText
          primary={
            <Fragment>
              <Typography variant="subtitle2">
                {notification.field_book_name}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.secondary" }}
              >
                {notification.title}
              </Typography>
            </Fragment>
          }
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}
            >
              <Iconify
                icon="eva:clock-outline"
                sx={{ mr: 0.5, width: 16, height: 16 }}
              />
              {moment(notification.sent_at).fromNow()}
            </Typography>
          }
        />
      </Link>
    </ListItemButton>
  );
}
