import { MenuItem, Select, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { useFetch } from "../../../hooks/useFetch";

const roles = [
  "Commercial",
  "Conducteur de travaux",
  "Chargé d’affaire",
  "Dessinateur",
  "Dirigeant",
  "Équipe travaux",
  "Interlocuteur Concessionnaire",
  "Technicien",
];

const Professional = ({ contactData, handleChange, setContactData }) => {
  const { responseData: enterprisesData, get } = useFetch(true);
  const [enterprises, setEnterprises] = useState([]);
  const [enterprise, setEnterprise] = useState({
    value: contactData?.enterprise_id || "",
    label: contactData.enterprise?.social_reason || "",
  });

  useEffect(() => {
    if (enterprisesData) {
      enterprisesData.forEach((enterprise) => {
        const data = { value: enterprise.id, label: enterprise.social_reason };
        const array = enterprises;
        array.push(data);
        setEnterprises(array);
      });
    } else {
      get("enterprises");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterprisesData]);

  useEffect(() => {
    setContactData({ ...contactData, social_reason: "", role: "Commercial" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeEntreprise = (e) => {
    let enterprise = enterprisesData.find(
      (enterprise) => enterprise.id === e.value
    );
    setEnterprise(e);
    setContactData({
      ...contactData,
      enterprise_id: e.value,
      address_quotation: contactData.address_quotation || enterprise.address,
      additional_address_quotation:
        contactData.additional_address_quotation ||
        enterprise.additional_address,
      city_quotation: contactData.city_quotation || enterprise.city,
      zipcode_quotation: contactData.zipcode_quotation || enterprise.zipcode,
    });
  };

  return (
    <>
      <Stack>
        <label>Role : </label>
        <Select
          name="role"
          required={true}
          style={{ textAlign: "left" }}
          value={contactData.role}
          onChange={handleChange}
        >
          {roles.map((role, index) => (
            <MenuItem key={index} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack>
        <label>Entreprise : </label>
        <ReactSelect
          name="enterprise_id"
          placeholder="Choisir une entreprise"
          options={enterprises}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
            control: (provided) => ({ ...provided, height: "56px" }),
          }}
          value={enterprise}
          onChange={(e) => handleChangeEntreprise(e)}
        />
      </Stack>
    </>
  );
};

export default Professional;
