import { TextField } from '@mui/material'
import React, { useEffect } from 'react'

const Other = ({contactData, setContactData, handleChange}) => {
    useEffect(() => {
        setContactData({...contactData,
            social_reason: "",
            role: ""})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  return (
    <>
        <TextField
            label="Role"
            name="role"
            required
            value={contactData.role}
            onChange={handleChange}
        />

    </>
  )
}

export default Other