import {
  Button,
  Modal,
  Stack,
  FormControlLabel,
  Typography,
  Radio,
  TextField,
  Card,
} from "@mui/material";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import Prospect from "./prospect";
import Professional from "./professional";
import Other from "./other";
import "./contact.scss";
import { toast } from "react-toastify";
import Iconify from "../../../components/iconify/Iconify";
import AutoCompleteCities from "../../../components/AutoCompleteCities";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

const ModalCreateContact = ({ open, setOpen }) => {
  const { responseData: contact, post } = useFetch(true);
  const [choice, setChoice] = useState("prospect");

  useEffect(() => {
    if (contact) {
      toast.success("Le contact a bien été créé");
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const [contactData, setContactData] = useState({
    role: "",
    social_reason: "",
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
    address_quotation: "",
    additional_address_quotation: "",
    city_quotation: "",
    zipcode_quotation: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    post(`contacts`, { contacts: contactData });
    setContactData({
      role: "",
      social_reason: "",
      firstname: "",
      lastname: "",
      email: "",
      phone_number: "",
      address_quotation: "",
      additional_address_quotation: "",
      city_quotation: "",
      zipcode_quotation: "",
    });
  };

  const handleChange = (event) => {
    setContactData((prevContactData) => {
      const { name, value } = event.target;
      if (name === "firstname") {
        return {
          ...prevContactData,
          firstname: value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase()
          ),
        };
      }
      if (name === "lastname") {
        return {
          ...prevContactData,
          lastname: value.toUpperCase(),
        };
      }
      if (name === "address_quotation") {
        return {
          ...prevContactData,
          address_quotation: value.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase()
          ),
        };
      }
      if (name === "city_quotation") {
        return {
          ...prevContactData,
          city_quotation: value.toUpperCase(),
        };
      }
      if (name === "email") {
        return {
          ...prevContactData,
          email: value.toLowerCase(),
        };
      }
      return {
        ...prevContactData,
        [name]: value,
      };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card
        className="card_contact_creation"
        style={{
          padding: "1rem",
          maxWidth: "95vw",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <form className="contact-form" onSubmit={handleSubmit}>
          <Stack direction={"column"} gap={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
              marginBottom={1}
            >
              <Typography variant="h4">Création de contact</Typography>
              <Button variant="contained" color="error" onClick={handleClose}>
                <Iconify icon="eva:close-fill" />
              </Button>
            </Stack>
            <RadioGroup
              name="use-radio-group"
              value={choice}
              onChange={(e) => setChoice(e.target.value)}
            >
              <Stack direction={"row"} justifyContent="space-around">
                <MyFormControlLabel
                  value="prospect"
                  label="Prospect"
                  control={<Radio />}
                />
                <MyFormControlLabel
                  value="professional"
                  label="Professionel"
                  control={<Radio />}
                />
                <MyFormControlLabel
                  value="other"
                  label="Autres"
                  control={<Radio />}
                />
              </Stack>
            </RadioGroup>
            <TextField
              label="Prénom"
              name="firstname"
              required={true}
              value={contactData.firstname}
              onChange={handleChange}
            />
            <TextField
              label="Nom"
              name="lastname"
              required={true}
              value={contactData.lastname}
              onChange={handleChange}
            />
            {choice === "prospect" && (
              <Prospect
                contactData={contactData}
                setContactData={setContactData}
                handleChange={handleChange}
              />
            )}
            {choice === "professional" && (
              <Professional
                contactData={contactData}
                setContactData={setContactData}
                handleChange={handleChange}
              />
            )}
            {choice === "other" && (
              <Other
                contactData={contactData}
                setContactData={setContactData}
                handleChange={handleChange}
              />
            )}
            <TextField
              label="Email"
              name="email"
              value={contactData.email}
              onChange={handleChange}
            />
            <TextField
              label="Téléphone"
              type="number"
              name="phone_number"
              value={contactData.phone_number}
              onChange={handleChange}
            />

            <AutoCompleteCities setAddress={setContactData} quotation={true} />

            <TextField
              label="Adresse"
              name="address_quotation"
              value={contactData.address_quotation}
              onChange={handleChange}
            />
            <TextField
              label="Complément d'adresse"
              name="additional_address_quotation"
              value={contactData.additional_address_quotation}
              onChange={handleChange}
            />
            <TextField
              label="Ville"
              name="city_quotation"
              value={contactData.city_quotation}
              onChange={handleChange}
            />
            <TextField
              type="number"
              label="Code postal"
              name="zipcode_quotation"
              value={contactData.zipcode_quotation}
              onChange={handleChange}
            />
            <Stack alignItems="center">
              <Button variant="contained" color="primary" type="submit">
                Créer
              </Button>
            </Stack>
          </Stack>
        </form>
      </Card>
    </Modal>
  );
};

export default ModalCreateContact;
