import { configureStore, combineReducers } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { COOKIE_TOKEN, COOKIE_ID } from '../config/config';
import { LOGIN, LOGOUT, GET_USER, UPDATE } from './actions'


const initialUserData = {
  id: Cookies.get(COOKIE_ID) || null,
  email: null,
  firstname: null,
  lastname: null,
  role: null,
  isLogged: Boolean(Cookies.get(COOKIE_TOKEN))
}

const userReducer = (state = initialUserData, payload) => {
  const { type, data, token } = payload;

  switch (type) {
    case GET_USER:
      if (!data || !data.id) {
        throw new Error('Data for login must not be empty.');
      }
      return {
        id: parseInt(data.id),
        role: data.role,
        firstname: data.firstname,
        lastname: data.lastname,
        phone_number: data.phone_number,
        email: data.email,
        avatar: data.avatar,
        isLogged: true
      }
    case LOGIN:
      if (!data || !data.id) {
        throw new Error('Data for login must not be empty.');
      }
      if (!token) {
        throw new Error('Missing token.');
      }
      Cookies.set(COOKIE_TOKEN, token, { sameSite: 'none', secure: true });
      Cookies.set(COOKIE_ID, data.id, { sameSite: 'none', secure: true });
      return {
        id: parseInt(data.id),
        role: data.role,
        firstname: data.firstname,
        lastname: data.lastname,
        phone_number: data.phone_number,
        email: data.email,
        avatar: data.avatar,
        isLogged: true
      };
    case UPDATE:
      if (!data || !data.id) {
        throw new Error('Data for login must not be empty.');
      }
      return {
        id: parseInt(data.id),
        firstname: data.firstname,
        lastname: data.lastname,
        phone_number: data.phone_number,
        email: data.email,
        avatar: data.avatar,
        isLogged: true
      };
    case LOGOUT:
      Cookies.remove(COOKIE_TOKEN);
      Cookies.remove(COOKIE_ID);
      return {
        id: null,
        role: null,
        firstname: null,
        lastname: null,
        phone_number: null,
        email: null,
        avatar: null,
        isLogged: false
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  user: userReducer,
});

export default configureStore({
  reducer: rootReducer
});
