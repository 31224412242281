import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';

// @mui
import { styled, alpha } from '@mui/material/styles';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { Box, List, ListItemText,Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import SvgColor from '../../../components/svg-color';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[100], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, user }) {
  const { pathname } = useLocation();
  const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}`} sx={{ width: 1, height: 1 }} />;
  const navConfig = [
    {
      title: 'dashboard',
      path: '/',
      icon: icon('ic_analytics.svg'),
    },
    {
      title: 'dossiers',
      path: '/fieldbooks',
      icon: icon('ic_dossier.png'),
    },
    {
      title: 'contacts',
      path: '/contacts',
      icon: icon('ic_user.svg'),
    },
    {
      title: 'concessionnaires',
      path: '/dealers',
      icon: icon('ic_concessionnaire.png'),
    }
  ];
  if (parseInt(user.role) === 4) {
    navConfig.push({title: 'gestion administrateur',
    path: '/administrator-panel',
    icon: icon('ic_lock.svg'),})
  }
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <NavLink to={"/users/" + user.id} underline="none">
            <StyledAccount>
              <Avatar src={user.avatar} alt="photoURL" />

              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'white' }}>
                  {user.firstname}
                </Typography>

                <Typography variant="body2" sx={{ color: 'white' }}>
                  {user.lastname}
                </Typography>
              </Box>
            </StyledAccount>
        </NavLink>
      </Box>

      <Box>
        <List disablePadding sx={{ p: 1 }}>
          {navConfig.map((item) => (
            <NavItem key={item.title} item={item} />
          ))}
        </List>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          {/* <Box
            component="img"
            src="/assets/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button> */}
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: '#444444',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH , bgcolor: '#444444',},
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}


NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'white',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
