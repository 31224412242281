// Heroku
export const API_URL = "https://reseaux-plus.herokuapp.com/"
// Local
// export const API_URL = "http://localhost:3000/"


export const COOKIE_TOKEN = '_ocr_token_session'; 
export const COOKIE_ID = '_ocr_id_session';
export const COOKIE_NOTIFICATION_SEARCH = '_ocr_notification_search';
// eslint-disable-next-line no-useless-escape
export const REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
