import { useState } from "react";
import Cookies from "js-cookie";
import { API_URL, COOKIE_TOKEN } from "../config/config";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../stores/actions";

export const useFetch = (withAuth = false) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [token, setToken] = useState(Cookies.get(COOKIE_TOKEN));
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const headers = {
    "Content-Type": "application/json",
  };

  if (withAuth && token) {
    headers.Authorization = token;
  }

  const get = (path) => {
    setIsLoading(true);
    setError(null);
    setStatus("getting");
    fetch(API_URL + path, {
      method: "get",
      headers,
    })
      .then((response) => {
        if (response.status === 401) {
          setToken(null);
          Cookies.remove(COOKIE_TOKEN);
          dispatch({ type: LOGOUT });
          toast.error("Vous n'êtes plus connecté, veuillez vous reconnecter");
        }
        if (response.status === 422) {
          toast.error("Impossible de procéder à la requête");
          setResponseData(null);
          setStatus("unproccessable");
        }
        return response.json();
      })
      .then((data) => {
        if (data.errors) {
          setError(data.errors);
        } else {
          setResponseData(data);
        }
      })
      .catch((errors) => setError(errors))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const post = (path, postData, stringify = true) => {
    setIsLoading(true);
    setError(null);
    setStatus("creating");
    let options = {};
    if (!stringify) {
      options = {
        method: "post",
        headers: {
          Authorization: token,
        },
        body: postData,
      };
    } else {
      options = {
        method: "post",
        headers,
        body: JSON.stringify(postData),
      };
    }
    fetch(API_URL + path, options)
      .then((response) => {
        if (response.status === 401) {
          setToken(null);
          Cookies.remove(COOKIE_TOKEN);
          dispatch({ type: LOGOUT });
          toast.error("Vous n'êtes plus connecté, veuillez vous reconnecter");
        }
        return response.json();
      })
      .then((data) => {
        if (path === "auth/login") {
          setToken(data.token);
          setResponseData(data);
        } else {
          setResponseData(data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
        setStatus("created");
      });
  };

  const patch = (path, postData, stringify = true) => {
    setIsLoading(true);
    setError(null);
    setStatus("updating");
    let options = {};
    if (!stringify) {
      options = {
        method: "PATCH",
        headers: {
          Authorization: token,
        },
        body: postData,
      };
    } else {
      options = {
        method: "PATCH",
        headers,
        body: JSON.stringify(postData),
      };
    }
    fetch(API_URL + path, options)
      .then((response) => {
        if (response.status === 401) {
          setToken(null);
          Cookies.remove(COOKIE_TOKEN);
          dispatch({ type: LOGOUT });
          toast.error("Vous n'êtes plus connecté, veuillez vous reconnecter");
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          setResponseData(data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
        setStatus("updated");
      });
  };

  const destroy = (path) => {
    setIsLoading(true);
    setError(null);
    setStatus("destroying");
    fetch(API_URL + path, {
      method: "DELETE",
      headers,
    })
      .then((response) => {
        if (response.status === 401) {
          setToken(null);
          Cookies.remove(COOKIE_TOKEN);
          dispatch({ type: LOGOUT });
          toast.error("Vous n'êtes plus connecté, veuillez vous reconnecter");
        }

        return response.json();
      })
      .then((data) => {
        if (data.error) {
          setError(data.error);
        }
        setResponseData(data);
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
        setStatus("destroyed");
      });
  };
  return {
    isLoading,
    error,
    status,
    responseData,
    token,
    headers,
    get,
    post,
    patch,
    destroy,
  };
};
