import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import Loader from '../Loader'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentPreview = ({ file }) => {
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  return (
    <Document 
      file={file} 
      onClick={() => window.open(file.url, '_blank')} 
      style={{cursor: 'pointer'}} 
      onLoadError={console.error} 
      onLoadSuccess={() => setIsDocumentLoaded(true)}
      loading={<Loader/>} >
        {isDocumentLoaded && <Page scale={0.1} pageIndex={0} pageNumber={1} />}
    </Document>
  )
}

export default DocumentPreview