import { Suspense, useEffect } from 'react';
import { 
  BrowserRouter as Router,
  Routes,
  Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useFetch } from './hooks/useFetch';
import { API_URL } from './config/config';
import { GET_USER, LOGOUT } from './stores/actions';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import DashboardLayout from './layouts/dashboard';

import { 
  Login, 
  Register, 
  NoMatch, 
  Dashboard,
  FieldBooks,
  CreateFieldBook,
  FieldBook,
  Contacts,
  Contact,
  UpdateContact,
  Dealers,
  Dealer,
  CreateDealer,
  UpdateDealer,
  User,
  UpdateUser,
  AdministratorPanel,
  TabCreateSite,
  CreateSiteConnection,
  CreateEnterprise,
  UpdateEnterprise,
  Enterprise,
  ForgotPassword,
  UpdateUserPassword,
  UpdateFieldBook,
  TabCreatePrestation,
  CreatePrestation,
} from './pages';

import { Alerts, Loader } from './components';
import { Stack } from '@mui/material';

const App = () => {
  const user = useSelector((state) => state.user);
  
  const { headers } = useFetch(true);
  const dispatch = useDispatch();

	const getUser = () => {
    fetch(`${API_URL}users/${user.id}`,{ headers })
    .then((response) => response.json())
	  .then ((data) => {
      if (data.error) {
        dispatch({ type: LOGOUT })
      } else {
        dispatch({ type: GET_USER, data });
      }
		})
    .catch(() => dispatch({ type: LOGOUT }))
  }

  useEffect(() => {
    if (user.id) {
      getUser()
    } 
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

  const autorisation = () => {
    return user.role === 1 || user.role === 2 || user.role === 4
  }

  return (
    <ThemeProvider>
      <Alerts>
        <Router>
          <Suspense fallback={
            <Stack sx={{ width: '100%', height: '100vh' }} spacing={2} alignItems="center" justifyContent="center">
              <Loader/>
            </Stack>}>
          
            { !user.isLogged && 
              <Routes>
                <Route path="/" exact element={<Login/>}/>
                <Route path="/signup" element={<Register/>}/> 
                <Route path="/forgotpassword" element={<ForgotPassword/>}/>
                <Route path='*' element={<NoMatch />}/>
              </Routes>}
            
            { user.isLogged && 
            <DashboardLayout>
              <ScrollToTop/>
              <Routes>
                <Route path="/" exact element={<Dashboard/>}/>

                {/* Users */}
                <Route path="/users/:id" exact element={<User/>}/>
                <Route path="/users/:id/update" exact element={<UpdateUser/>}/>
                <Route path="/users/:id/password" exact element={<UpdateUserPassword/>}/>

                {/* FieldBooks */}
                <Route path="/fieldbooks" element={<FieldBooks/>}/>
                { autorisation() && <Route path="/fieldbooks/create" exact element={<CreateFieldBook/>}/> }
                <Route path="/fieldbooks/:id" exact element={<FieldBook/>}/>
                { user.role !== 0 && <Route path="/fieldbooks/:id/update" exact element={<UpdateFieldBook/>}/> }
                <Route path="/fieldbooks/:id/siteconnections/tabcreate" exact element={<TabCreateSite/>}/>
                <Route path="/fieldbooks/:id/prestations/tabcreate" exact element={<TabCreatePrestation/>}/>
                <Route path="/fieldbooks/:id/siteconnections/create" exact element={<CreateSiteConnection/>}/>
                <Route path="/fieldbooks/:id/prestations/create" exact element={<CreatePrestation/>}/>
                
                {/* Contacts */}
                <Route path="/contacts" element={<Contacts/>}/>
                <Route path="/contacts/:id" exact element={<Contact/>}/>
                { user.role !== 0 && <Route path="/contacts/:id/update" exact element={<UpdateContact/>}/>}
                
                {/* Enterprise */}
                <Route path="/enterprises/create" element={<CreateEnterprise/>}/>
                <Route path="/enterprises/:id" exact element={<Enterprise/>}/>
                { user.role !== 0 && <Route path="/enterprises/:id/update" exact element={<UpdateEnterprise/>}/>}
                
                {/* Dealers */}
                <Route path="/dealers" element={<Dealers/>}/>
                <Route path="/dealers/:id" exact element={<Dealer/>}/>
                { autorisation() && <Route path="/dealers/create" exact element={<CreateDealer/>}/>}
                { user.role !== 0 && <Route path="/dealers/:id/update" exact element={<UpdateDealer/>}/>}
                
                {/* AdministratorPanel */}
                { user.role === 4 && <Route path="/administrator-panel" element={<AdministratorPanel/>}/>} 
                
                <Route path='*' element={<NoMatch />}/>
              </Routes>
            </DashboardLayout> }
            
          </Suspense>
        </Router>  
      </Alerts>    
    </ThemeProvider>
  );
};

export default App;
