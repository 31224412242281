import { Avatar, Button, MenuItem, Select, Stack, Typography } from '@mui/material';
import React from 'react'
import Mail from '../Mail'
import { useNavigate } from 'react-router-dom'

const LiUser = ({user, handleApproved, handleChangeRole}) => {
  const navigate = useNavigate();
  return (
    <Stack 
    style={{ borderBottom: "1px solid rgba(0,0,0, 0.1)", minWidth: "600px"}}
    padding={2}
    direction='row' 
    justifyContent='space-between' 
    alignItems='center'>
          <Stack style={{cursor: 'pointer', width: '200px'}} onClick={() => navigate("/users/" + user.id)} direction='row' alignItems='center' justifyContent='flex-start' spacing={2}>
            <Avatar src={user.avatar} alt="photoURL" />
            <Typography variant='p'>{user.firstname} {user.lastname}</Typography>
          </Stack>
          <Mail 
              recipient={user.email}
              subject={"Compte Réseaux Plus Approuvé"}
              body={`Bonjour ${user.firstname},\n\nVous pouvez maintenant vous connecter à l'application Réseaux Plus.\n\nCordialement,\n\nL'équipe Réseaux Plus`}
          />
          <Select
          style={{width: "200px"}}
          value={user.role}
          onChange={(e) => handleChangeRole(user.id, e.target.value)}
          name="role"
          >
            <MenuItem value={0}>Visiteur</MenuItem>
            <MenuItem value={1}>Secrétariat</MenuItem>
            <MenuItem value={2}>Bureau d'étude</MenuItem>
            <MenuItem value={3}>Service travaux</MenuItem>
            <MenuItem value={4}>Administrateur</MenuItem>
          </Select>
          {!(user.approved) && 
          <Button onClick={() => handleApproved(user.id)}>
            <img alt="edit" src='/assets/icons/ic_coche.png' style={{ width: 20, height: 20 }}/>
          </Button>}
    </Stack>
  )
}

export default LiUser