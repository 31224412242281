import { TextField } from '@mui/material'
import React, { useEffect } from 'react'

const Prospect = ({handleChange, contactData, setContactData}) => {
    useEffect(() => {
        setContactData({...contactData,
            social_reason: contactData?.social_reason || "",
            role: "Prospect"})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactData])



  return (
    <>
        <TextField
            label="Raison social (pro)"
            name="social_reason"
            value={contactData?.social_reason || ""}
            onChange={handleChange}
        />
        
    </>
  )
}

export default Prospect